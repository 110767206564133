import React from "react"
import clsx from "clsx"
import styles from "./Badge.module.css"

interface Props {
  title: string
  color?: "neutral" | "neutralGold" | "success" | "attention" | "warning" | "critical"
  icon?: React.ReactNode
  type?: "default" | "square" | "light" | "dot"
}

export const Badge = ({ title, color = "neutral", icon, type = "default" }: Props) => {
  let colorStyle = styles.container
  switch (color) {
    case "neutral":
      colorStyle = clsx(colorStyle, styles.neutral)
      break
    case "neutralGold":
      colorStyle = clsx(colorStyle, styles.neutralGold)
      break
    case "success":
      colorStyle = clsx(colorStyle, styles.success)
      break
    case "attention":
      colorStyle = clsx(colorStyle, styles.attention)
      break
    case "warning":
      colorStyle = clsx(colorStyle, styles.warning)
      break
    case "critical":
      colorStyle = clsx(colorStyle, styles.critical)
      break
  }

  switch (type) {
    case "default":
      break
    case "square":
      colorStyle = clsx(colorStyle, styles.square)
      break
    case "light":
      colorStyle = clsx(colorStyle, styles.light)
      break
    case "dot":
      colorStyle = clsx(colorStyle, styles.dot)
      break
  }

  return (
    <div className={colorStyle} data-cy={`badge-${title}`}>
      {icon && <span className={styles.icon}>{icon}</span>}
      {type === "dot" && <div className={styles.dotWrapper} />}
      {title}
    </div>
  )
}
