/**
 * @fileOverview Helpers for formatting numbers, currencies, strings, offer objects.
 * @author Colin Bethea (colin@candor.co)>
 * @version 1.0
 */

import type { WealthAddresses } from "candor-common"
import { parsePhoneNumber } from "libphonenumber-js"
import moment, { Moment } from "moment"
import xss from "xss"
import getCompensationComponents from "./getCompensationComponents"

/**
 *
 * @param phoneNumber onboarding phone number
 * @returns E.164 phone number
 */
export const formatPhoneNumberToE164 = (phoneNumber) => {
  if (!phoneNumber) {
    return null
  }

  let result
  try {
    result = parsePhoneNumber(phoneNumber)
    const e164Number = result.number

    return e164Number
  } catch (e) {
    return null
  }
}

/**
 *
 * @param phoneNumber onboarding phone number
 * @returns formatted phone number for apex
 */
export const formatApexPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return null
  }

  let result: any = parsePhoneNumber(phoneNumber)
  result = result.nationalNumber
  const apexFormattedPhoneNumber = result.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")

  return apexFormattedPhoneNumber
}

/**
 * Format a raw SSN
 * @module formatSSN
 * @param {string} ssn raw ssn
 * @returns {string} formatted ssn
 */

export const formatSSN = (ssn) => {
  ssn = ssn.replace(/\D/g, "")
  const match = ssn.match(/^(\d{3})(\d{2})(\d{4})$/)
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3]
  }
  return null
}

/**
 * Format currency (USD)
 * @module formatCurrency
 * @param {number} number raw number
 * @returns {string} formatted currency
 */

export const formatCurrency = (number, showDecimals = true) =>
  Intl
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: showDecimals ? 2 : 0,
        maximumFractionDigits: showDecimals ? 2 : 0,
      }).format(number)
    : `$${number}`

/**
 * Format stock price (USD) - up to 4 decimal points for stock prices according to SEC guidelines
 * Rare case to need more than 2 decimals precision, so limiting it only to stocks that are trading below $1
 * @module formatCurrency
 * @param {number} number raw number
 * @returns {string} formatted stock price
 */

export const formatStockPrice = (number, showDecimals = true) =>
  Intl
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: showDecimals ? 2 : 0,
        maximumFractionDigits: showDecimals ? (Number(number) < 1 ? 4 : 2) : 0,
      }).format(number)
    : `$${number}`

/**
 * Format number to 2 decimal places
 * @module formatDoubleDecimal
 * @param {number} number raw number
 * @returns {string} formatted number
 */

export const formatDoubleDecimal = (
  number: number,
  showDecimals = true,
  withPlusSign = false,
  decimalPoints = 2,
  withMinusSign = false
) => {
  const formattedNumber = Intl
    ? new Intl.NumberFormat("en-US", {
        minimumFractionDigits: showDecimals ? decimalPoints : 0,
        maximumFractionDigits: showDecimals ? decimalPoints : 0,
      }).format(number)
    : `${number}`
  if (withMinusSign && number < 0) {
    return "-" + formattedNumber
  } else if (withPlusSign && number > 0) {
    return "+" + formattedNumber
  }
  return formattedNumber
}

/**
 * Format number (international)
 * @module formatNumber
 * @param {number} number raw number
 * @returns {string} formatted number
 */
export const formatNumber = (number) => {
  return typeof Intl == "object" && typeof Intl.NumberFormat == "function" ? number.toLocaleString() : number
}

/**
 * Format number (international)
 * @module formatNumber
 * @param {number} number
 * @returns {string} formatted number into percentage e.g 52.0%
 */
export const formatPercentage = (number: number, decimalPoints = 1, withPlusSign = false): string => {
  if (number === null || number === undefined) {
    return
  }
  return (number > 0 && withPlusSign ? "+" : "") + `${number.toFixed(decimalPoints)}%`
}

/**
 * Format offer based on viewing, posting privileges
 * @module formatOffer
 * @param {object} offer offer object
 * @param {object} req request object
 * @param {string} description poster description
 * @returns {string} formatted offer object
 */

export const formatOffer = ({ offer = null, req = null, description }) => {
  if (!offer) {
    return null
  } else {
    let tempOffer = {
      ip_address: req.headers["x-forwarded-for"] || req.headers["x-real-ip"] || req.connection.remoteAddress || null,
      description: description && description.length > 0 ? xss(description) : null,
      total_comp: offer.is_internship ? offer.monthly_salary * 12 : parseInt(offer.total_comp, 10),
      logo:
        offer.company && offer.company.logo
          ? `http://logo.clearbit.com/${offer.company.logo}`
          : "https://candor.co/images/icons/company.png",
      company_name: offer.company && offer.company.content ? offer.company.content : xss(offer.company),
      company_id: offer.company && offer.company.id ? offer.company.id : null,
      custom_company: offer.company && offer.company.id ? null : xss(offer.company),
      role: offer.role,
      role_name: offer.role && offer.role.label ? offer.role.label : xss(offer.role),
      role_id: offer.role && offer.role.value && offer.role.value.id ? offer.role.value.id : null,
      custom_role: offer.role && offer.role.value && offer.role.value.id ? null : xss(offer.role.label),
      level: offer.level,
      level_name: offer.level && offer.level.content ? offer.level.content : xss(offer.level),
      level_id: offer.level && offer.level.id ? offer.level.id : null,
      custom_level: offer.level && offer.level.id ? null : xss(offer.level),
      location: offer.location,
      location_name: offer.location && offer.location.label ? offer.location.label : xss(offer.location.label),
      location_id: offer.location && offer.location.value && offer.location.value.id ? offer.location.value.id : null,
      custom_location:
        offer.location && offer.location.value && offer.location.value.id ? null : xss(offer.location.label),
      base_salary:
        (offer.is_internship ? parseInt(offer.monthly_salary) * 12 : parseInt(offer.base_salary)) || undefined,
      equity_grant_type: offer.equity_grant_type,
      equity_grant_duration: parseInt(offer.equity_grant_duration) || undefined,
      equity_grant_value: parseInt(offer.equity_grant_value) || undefined,
      equity_grant_share_number: parseInt(offer.equity_grant_share_number) || undefined,
      share_strike_price: offer.share_strike_price,
      share_preferred_price: offer.share_preferred_price,
      signon_bonus: parseInt(offer.signon_bonus) || undefined,
      signon_bonus_year_two: parseInt(offer.signon_bonus_year_two) || undefined,
      relocation_bonus: parseInt(offer.relocation_bonus) || undefined,
      target_performance_bonus: parseInt(offer.target_performance_bonus) || undefined,
      target_performance_bonus_type: offer.target_performance_bonus_type,
      target_commission: parseInt(offer.target_commission) || undefined,
      target_commission_type: offer.target_commission_type,
      equity_grant_vesting_schedule: offer.equity_grant_vesting_schedule.join(","),
      offer_letter_key: offer.offer_letter_key || null,
      is_internship: offer.is_internship || false,
      monthly_salary: parseInt(offer.monthly_salary) || undefined,
      stipend_value: parseInt(offer.stipend_value) || undefined,
      is_current_salary: offer.offer_date ? true : offer.is_current_salary,
      offer_date: offer.offer_date ? moment(offer.offer_date).utc().format() : null,
    }

    tempOffer = getCompensationComponents({ post: tempOffer, eligibleForViewing: true, requirePercentages: true })

    return tempOffer
  }
}

export const formatDate = ({
  date,
  short,
  ignoreTimezone,
  calendar,
}: {
  date: Date | Moment | string
  short?: boolean
  ignoreTimezone?: boolean
  calendar?: boolean
}) => {
  if (ignoreTimezone) {
    return short ? moment.utc(date).format("ll") : moment.utc(date).format("LL")
  } else if (calendar) {
    return moment.utc(date).format("MM/DD/YYYY")
  } else {
    return short ? moment(date).format("ll") : moment(date).format("LL")
  }
}

export const formatAddress = (address: WealthAddresses) => {
  // TODO intl support
  const { streetAddress, country, city, postalCode, state } = address

  return `${streetAddress}\n${city}, ${state} ${postalCode} ${country}`
}

export const formatNationalPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return ""
  }

  let formattedNationalPhoneNumber = ""
  try {
    const phoneNumberObj = parsePhoneNumber(phoneNumber)
    formattedNationalPhoneNumber = phoneNumberObj.formatNational()
    return formattedNationalPhoneNumber
  } catch (e) {
    return ""
  }
}

export const getOrdinalSuffix = (num: number) => {
  const j = num % 10,
    k = num % 100
  if (j == 1 && k != 11) {
    return num + "st"
  }
  if (j == 2 && k != 12) {
    return num + "nd"
  }
  if (j == 3 && k != 13) {
    return num + "rd"
  }
  return num + "th"
}

export const formatCurrencyForUi = (value: number): string => {
  if (!value) return "$0"

  if (value >= 1_000_000) return (value / 1_000_000).toFixed(1) + "M"
  if (value >= 1_000) return (value / 1_000).toFixed(0) + "K"
  return value.toString()
}
